import React from 'react';
import classnames from 'classnames';

import { LoadingChip } from './LoadingChip';

export const FfmpegLoadingStatus = ({ ffmpegIsReady, className }) => {
  return (
    <div className={classnames('d-inline-block', className)}>
      <LoadingChip
        status={ffmpegIsReady ? 'loaded' : 'loading'}
        label={ffmpegIsReady ? 'Codebase loaded' : 'Loading codebase ...'}
      />
    </div>
  );
};
