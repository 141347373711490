import React from 'react';

import { useApp } from '../contexts/appContext';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/alert';

export const Notifications = () => {
  const {
    state: { notification },
    closeNotification,
  } = useApp();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={5000}
      open={notification.open}
      message="I love snacks"
    >
      <Alert onClose={closeNotification} severity={notification.severity}>
        {notification.message}
      </Alert>
    </Snackbar>
  );
};
