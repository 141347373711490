import React from 'react';
import Dropzone from './Dropzone';
import { useApp } from '../contexts/appContext';
import { requestSubCuts } from '../utils';
import { extractLength } from '../computeSyncedSub';
import DroppedItem from './DroppedItem';
import { LoadingBadge } from './LoadingBadge';

import Typography from '@material-ui/core/Typography';
import ShortText from '@material-ui/icons/ShortText';
import Theaters from '@material-ui/icons/Theaters';

const Dropzones = () => {
  const {
    state: { movie, subtitles, status },
    dispatch,
    addNotification,
  } = useApp();

  const onDropMovie = (acceptedFiles) => {
    if (acceptedFiles.length) {
      dispatch({
        type: 'SET_MOVIE_LOADING',
      });
      acceptedFiles.forEach((file) => {
        // console.log('file info:', file);
        // console.log(typeof file);
        // console.log(Object.keys(file));
        // console.log('file path :', file.path);

        const reader = new FileReader();

        reader.onabort = () =>
          addNotification({
            message: 'file reading was aborted',
            severity: 'error',
          });
        reader.onerror = () =>
          addNotification({
            message: 'file reading has failed',
            severity: 'error',
          });
        reader.onload = () => {
          console.log(`path: ${reader.result}, filename: ${file.path}`);
          dispatch({
            type: 'SET_MOVIE',
            payload: { arrayBuffer: reader.result, filename: file.path },
          });
        };
        reader.readAsArrayBuffer(file);
      }, []);
    } else {
      addNotification({
        message: 'wrong file format',
        severity: 'warning',
        timeout: 5000,
      });
    }
  };

  const onDropSub = (acceptedFiles) => {
    if (acceptedFiles.length) {
      dispatch({
        type: 'SET_SUBTITLES_LOADING',
      });
      acceptedFiles.forEach((file) => {
        // console.log('file info:', file);
        // console.log(typeof file);
        // console.log(Object.keys(file));
        // console.log('file path :', file.path);

        const reader = new FileReader();

        reader.onabort = () =>
          addNotification({
            message: 'file reading was aborted',
            severity: 'error',
          });
        reader.onerror = () =>
          addNotification({
            message: 'file reading has failed',
            severity: 'error',
          });
        reader.onload = () => {
          const subFileContent = reader.result;

          dispatch({
            type: 'SET_SUBTITLES',
            payload: { file: subFileContent, filename: file.name },
          });

          requestSubCuts({ extractLength, subFile: subFileContent, dispatch });
        };
        reader.readAsText(file);
      }, []);
    } else {
      addNotification({
        message: 'wrong file format',
        severity: 'warning',
        timeout: 5000,
      });
    }
  };

  return (
    <div className="d-flex justify-content-center py-4 w-100">
      <div className="mr-3 w-50 position-relative flex-grow-0">
        <Dropzone
          onDrop={onDropMovie} // TODO: onDrop
          accept=".mkv,.avi,.mp4,.webm,.wmv,.mov,.flv,.webm"
          disabled={movie.isSelected || status.working}
        >
          {!movie.isSelected ? (
            <>
              <p>Drop your movie here (english)</p>
              <Typography variant="caption" display="block" gutterBottom>
                .avi, .mkv, .mp4, ...
              </Typography>
            </>
          ) : (
            <DroppedItem
              filename={movie.filename}
              icon={<Theaters />}
              onDelete={() => dispatch({ type: 'DELETE_MOVIE' })}
            />
          )}
        </Dropzone>
        <div className="position-absolute" style={{ top: 4, left: '-14px' }}>
          <LoadingBadge
            status={
              movie.isLoading
                ? 'loading'
                : movie.isSelected
                ? 'loaded'
                : 'notLoaded'
            }
          />
        </div>
      </div>

      <div className="ml-3 w-50 position-relative flex-grow-0">
        <Dropzone
          onDrop={onDropSub}
          accept=".srt"
          disabled={subtitles.isSelected || status.working}
        >
          {!subtitles.isSelected ? (
            <>
              <p>Drop your subtitles here (english)</p>
              <Typography variant="caption" display="block" gutterBottom>
                .srt
              </Typography>
            </>
          ) : (
            <DroppedItem
              filename={subtitles.filename}
              icon={<ShortText />}
              onDelete={() => dispatch({ type: 'DELETE_SUBTITLES' })}
            />
          )}
        </Dropzone>
        <div className="position-absolute" style={{ top: 4, left: '-14px' }}>
          <LoadingBadge
            status={
              subtitles.isLoading
                ? 'loading'
                : subtitles.isSelected
                ? 'loaded'
                : 'notLoaded'
            }
          />
        </div>
      </div>
    </div>
  );
};
export default Dropzones;
