import axios from 'axios';
import { Base64 } from 'js-base64';

export const extractLength = 55; // in seconds

const doTranscode = async ({
  ffmpeg,
  filename,
  arrayBuffer,
  cutTimes,
  dispatch,
}) => {
  dispatch({
    type: 'SET_PROGRESS',
    payload: { progress: 10, message: 'Writing file' },
  });

  await ffmpeg.write('movie.mp4', arrayBuffer);
  await ffmpeg.write('movie-2.mp4', arrayBuffer);

  ///////////////// First cut /////////////////

  dispatch({
    type: 'SET_PROGRESS',
    payload: {
      progress: 25,
      message: `Analysing video`,
    },
  });

  await ffmpeg.trim(
    'movie.mp4',
    'movie-trim.mp4',
    cutTimes.first,
    cutTimes.first + extractLength,
    '-y -vcodec copy -acodec copy'
  );

  dispatch({
    type: 'SET_PROGRESS',
    payload: { progress: 25, message: 'Transcoding' },
  });

  await ffmpeg.transcode(
    'movie-trim.mp4',
    'audio.flac',
    '-y -vn -ab 160k -ac 1 -ar 16000 -acodec flac -threads 2'
  ); // -f s16le -acodec pcm_s16le --> .raw

  const firstAudioData = await ffmpeg.read('audio.flac');

  dispatch({
    type: 'SET_PROGRESS',
    payload: { progress: 35, message: 'Trimming' },
  });

  ///////////////// Second cut /////////////////
  await ffmpeg.trim(
    'movie.mp4',
    'movie-trim-2.mp4',
    cutTimes.second,
    cutTimes.second + extractLength,
    '-y -vcodec copy -acodec copy'
  );

  dispatch({
    type: 'SET_PROGRESS',
    payload: { progress: 45, message: 'Bundling request' },
  });

  await ffmpeg.transcode(
    'movie-trim-2.mp4',
    'audio-2.flac',
    '-y -vn -ab 160k -ac 1 -ar 16000 -acodec flac -threads 2'
  ); // -f s16le -acodec pcm_s16le --> .raw

  const secondAudioData = await ffmpeg.read('audio-2.flac');

  ///////////////// Request /////////////////
  const config = {
    encoding: 'FLAC',
    sampleRateHertz: 16000,
    languageCode: 'en-US',
    enableWordTimeOffsets: true,
  };

  const firstAudio = {
    content: Base64.fromUint8Array(firstAudioData),
  };

  const secondAudio = {
    content: Base64.fromUint8Array(secondAudioData),
  };

  const request = {
    config: config,
    audio: { first: firstAudio, second: secondAudio },
  };

  return request;
};

const requestTranscript = async (request) => {
  const response = await axios({
    method: 'post',
    //url: 'http://localhost:5001/subsync-57348/us-central1/requestTranscripts',
    url:
      'https://us-central1-subsync-57348.cloudfunctions.net/requestTranscripts ',
    data: request,
    timeout: 90000,
  });

  console.log('transcript:', response.data);

  return response.data;
};

const requestSyncedSub = async ({ request, subFileName, callback }) => {
  const newSubFileName =
    subFileName.slice(0, subFileName.length - 4) +
    '-synced' +
    subFileName.slice(subFileName.length - 4);

  axios({
    method: 'post',
    url: 'https://us-central1-subsync-57348.cloudfunctions.net/sync_subs',
    // url: 'http://0.0.0.0:8080',
    data: request,
    timeout: 60000,
  })
    .then((response) => {
      // TODO: download from server and not from client ?
      const data = new Blob([response.data], { type: 'text/plain' });
      const csvURL = window.URL.createObjectURL(data);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', newSubFileName);
      tempLink.click();
      callback();
    })
    .catch((response) => console.error(response));
};

export const computeSyncedSub = async ({
  ffmpeg,
  movie,
  subtitles,
  cutTimes,
  dispatch,
  addNotification,
}) => {
  const transcriptRequest = await doTranscode({
    ffmpeg,
    filename: movie.filename,
    arrayBuffer: movie.arrayBuffer,
    cutTimes,
    dispatch,
  });

  dispatch({
    type: 'SET_PROGRESS',
    payload: { progress: 55, message: 'Awaiting model results' },
  });

  const transcripts = await requestTranscript(transcriptRequest);

  dispatch({
    type: 'SET_PROGRESS',
    payload: { progress: 80, message: 'Synchronizing subtitles' },
  });

  await requestSyncedSub({
    request: {
      transcripts,
      transcript_offsets: cutTimes,
      sub_file: subtitles.file,
    },
    subFileName: subtitles.filename,
    callback: () => {
      dispatch({
        type: 'SET_PROGRESS',
        payload: { progress: 100, message: 'Done' },
      });
      addNotification({
        message: 'Subtitles downloaded',
        severity: 'success',
      });
    },
  });
};
