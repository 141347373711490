import React from 'react';

import { useApp } from '../contexts/appContext';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import { LoadingChip } from './LoadingChip';

export const ProgressModal = () => {
  const {
    state: { status },
    dispatch,
  } = useApp();

  return (
    <Modal open={status.modalOpen}>
      <div className="d-flex justify-content-center align-items-center h-100">
        <Paper elevation={2}>
          <div className="px-3 pb-2 pt-3 d-flex flex-column align-items-center">
            <div className="loading-bar">
              <LinearProgress variant="determinate" value={status.progress} />
            </div>
            <div className="pb-2 pt-3 d-flex justify-content-center">
              <LoadingChip
                status={status.done ? 'loaded' : 'loading'}
                label={status.progressMessage}
              />
            </div>
            {status.done && (
              <Button
                className="my-2"
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => dispatch({ type: 'REINIT' })}
              >
                Close
              </Button>
            )}
          </div>
        </Paper>
      </div>
    </Modal>
  );
};
