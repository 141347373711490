import React, { useState, useEffect } from 'react';
import { createFFmpeg } from '@ffmpeg/ffmpeg';
import { computeSyncedSub } from '../computeSyncedSub';
import Dropzones from './Dropzones';
import { ProgressModal } from './ProgressModal';
import { FfmpegLoadingStatus } from './FfmpegLoadingStatus';
import { useApp } from '../contexts/appContext';
import { Notifications } from './Notifications';
import { ComingSoon } from './ComingSoon';

// import firebase from 'firebase';
// import firebaseui from 'firebaseui';
import Button from '@material-ui/core/Button';

// TODO: check if window.chrome is true, else redirect to chrome

// TODO: Implement - report a bug, request a feature, satisfaction rating

// TODO: Suggest subtitle with ruby-osdb ( as a paying feature ? )

// TODO: send movie size / format / meta and execution report to server for stats
// stats can then be used to predict execution time

// TODO: allow user to extend window ?

function MainPage() {
  const [ffmpeg, setFfmpeg] = useState(null);

  const {
    state: { status, movie, subtitles, cutTimes },
    dispatch,
    addNotification,
  } = useApp();

  // Load ffmpeg immediately
  useEffect(() => {
    // Initialize the FirebaseUI Widget using Firebase.
    // const ui = new firebaseui.auth.AuthUI(firebase.auth());
    const ffmpeg = createFFmpeg({
      corePath: './ffmpeg/ffmpeg-core.js',
      //corePath: 'https://unpkg.com/@ffmpeg/ffmpeg@0.7.1/dist/ffmpeg.min.js',
      log: true,
      // logger: (p) => {
      //   // TODO: do something with the log
      // },
    });
    setFfmpeg(ffmpeg);
    (async function () {
      if (ffmpeg) {
        await ffmpeg.load();
        dispatch({ type: 'FFMPEG_READY' });
      }
    })();
  }, []); // Dependencies are deliberately missing to execute only once

  // TODO : execute when resetting
  const resetFfmpegFile = async () => {
    ffmpeg.remove('movie.mp4');
  };

  return (
    <div>
      <div className="container d-flex flex-column align-items-center">
        <div className="alert alert-primary mt-3 w-100">
          <p>
            We synchronize automatically your subtitles file with your movie.
          </p>
          <p>
            After downloading, just add the new subtitles in your video player.
          </p>
        </div>

        <Dropzones />
        <FfmpegLoadingStatus
          ffmpegIsReady={status.ffmpegReady}
          className="pb-4"
        />
        <div className="d-flex justify-content-center">
          <Button
            className="bangers text-md"
            variant="contained"
            size="large"
            color="primary"
            onClick={async () => {
              await computeSyncedSub({
                ffmpeg,
                movie,
                subtitles,
                cutTimes,
                dispatch,
                addNotification,
              });
            }}
            disabled={
              status.working ||
              status.done ||
              !movie.isSelected ||
              !subtitles.isSelected ||
              !status.ffmpegReady
            }
          >
            Synchronize
          </Button>
        </div>
        <div className="w-100 py-3">
          <ComingSoon />
        </div>
      </div>

      <Notifications />
      <ProgressModal />
    </div>
  );
}

export default MainPage;
