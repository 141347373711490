import React from 'react';

export const ComingSoon = () => {
  return (
    <div className="d-inline-block alert alert-warning">
      <p>Coming soon !</p>

      <div>
        <div>&rarr; Multiple languages</div>
        <div>&rarr; Larger files handling</div>
        <div>&rarr; Faster processing</div>
      </div>
    </div>
  );
};
