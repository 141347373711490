import React from 'react';
import { useDropzone } from 'react-dropzone';

const Dropzone = ({ accept, onDrop, disabled, children }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple: false,
    disabled,
  });

  return (
    <div
      {...getRootProps()}
      className={`w-100 dropzone`}
      style={{ height: '200px', display: 'inline-block' }}
    >
      <input {...getInputProps()} />
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        {children}
      </div>
    </div>
  );
};
export default Dropzone;
