import React, { useState } from 'react';

import { VotingPopover } from './reporting/VotingPopover';

import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <AppBar position="sticky">
      <div className="container">
        <Toolbar variant="dense" className="d-flex justify-content-between p-0">
          <div className="d-flex align-items-center">
            <img
              src="sloth.svg"
              alt="sloth img"
              style={{ height: '62px' }}
              className="pr-3"
            />
            <div className="d-flex flex-column mt-1">
              <div
                className="bangers text-lg"
                style={{
                  lineHeight: '35px',
                  height: '36px',
                  letterSpacing: '3px',
                }}
              >
                SUBSYNC (beta)
              </div>
              <div
                className="bangers text-sm pl-5 hide-on-small-screens"
                style={{ letterSpacing: '3px' }}
              >
                Sit back and relax
              </div>
            </div>
          </div>
          <Button color="inherit" onClick={() => setIsOpen(true)}>
            Give a feedback
          </Button>
        </Toolbar>
      </div>

      <VotingPopover
        isOpen={isOpen}
        title="Give a feedback"
        close={() => setIsOpen(false)}
      />
    </AppBar>
  );
};
