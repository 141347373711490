import axios from 'axios';

export const requestSubCuts = async ({ extractLength, subFile, dispatch }) => {
  axios({
    method: 'post',
    url: 'https://us-central1-subsync-57348.cloudfunctions.net/get_speech_cuts',
    // url: 'http://0.0.0.0:8081',
    data: {
      speech_length: extractLength,
      sub_file: subFile,
    },
    timeout: 5000,
  })
    .then((response) => {
      console.log(
        'speech cuts:',
        response.data.firstCut,
        response.data.secondCut
      );
      dispatch({
        type: 'SET_CUT_TIMES',
        payload: {
          first: response.data.firstCut,
          second: response.data.secondCut,
        },
      });
    })
    .catch((response) => console.error(response));
};
