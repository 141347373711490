import React, { createContext, useContext, useReducer } from 'react';
import produce from 'immer';

const initialState = {
  db: null,
  status: {
    ffmpegReady: false,
    working: false,
    done: false,
    modalOpen: false,
    progress: null,
    progressMessage: '',
  }, // 0 - 100
  movie: {
    arrayBuffer: null,
    filename: null,
    isSelected: false,
    loading: false,
  },
  subtitles: { file: null, filename: null, isSelected: false, loading: false },
  cutTimes: { first: null, second: null },
  notification: { open: false, message: '', severity: '', id: 0 }, // error, warning, info, success
};

export const appContext = createContext(initialState);

export const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    (state, { type, payload }) =>
      produce(state, (draft) => {
        switch (type) {
          case 'SET_DB':
            draft.db = payload.db;
            break;

          case 'FFMPEG_READY':
            draft.status.ffmpegReady = true;
            break;

          case 'SET_PROGRESS':
            draft.status.progress = payload.progress;
            draft.status.progressMessage = payload.message;

            if (payload.progress === 100) {
              draft.status.working = false;
              draft.status.done = true;
            } else {
              draft.status.working = true;
              draft.status.done = false;
              draft.status.modalOpen = true;
            }
            break;

          case 'REINIT':
            draft.status = initialState.status;
            draft.status.ffmpegReady = true;
            draft.movie = initialState.movie;
            draft.subtitles = initialState.subtitles;
            draft.cutTimes = initialState.cutTimes;
            break;

          case 'SET_MOVIE_LOADING':
            draft.movie.isLoading = true;
            break;

          case 'SET_MOVIE':
            draft.movie.isLoading = false;
            draft.movie.isSelected = true;
            draft.movie.arrayBuffer = payload.arrayBuffer;
            draft.movie.filename = payload.filename;
            break;

          case 'DELETE_MOVIE':
            draft.movie.isSelected = false;
            draft.movie.arrayBuffer = null;
            draft.movie.filename = null;
            break;

          case 'SET_SUBTITLES_LOADING':
            draft.subtitles.isLoading = true;
            break;

          case 'SET_SUBTITLES':
            draft.subtitles.isLoading = false;
            draft.subtitles.isSelected = true;
            draft.subtitles.file = payload.file;
            draft.subtitles.filename = payload.filename;
            break;

          case 'DELETE_SUBTITLES':
            draft.subtitles.isSelected = false;
            draft.subtitles.path = null;
            draft.subtitles.filename = null;
            break;

          case 'SET_CUT_TIMES':
            draft.cutTimes.first = payload.first;
            draft.cutTimes.second = payload.second;
            break;

          case 'ADD_NOTIFICATION':
            draft.notification.open = true;
            draft.notification.id = payload.id;
            draft.notification.message = payload.message;
            draft.notification.severity = payload.severity;
            break;

          case 'TIMEOUT_NOTIFICATION':
            if (draft.notification.id === payload.id) {
              draft.notification.open = false;
            }
            break;

          case 'CLOSE_NOTIFICATION':
            draft.notification.open = false;
            break;

          default:
            throw new Error();
        }
      }),
    initialState
  );

  const closeNotification = () => dispatch({ type: 'CLOSE_NOTIFICATION' });

  const addNotification = ({ message, severity, timeout = 12000 }) => {
    const id = state.notification.id + 1;
    dispatch({
      type: 'ADD_NOTIFICATION',
      payload: { message, severity, id },
    });
    setTimeout(() => {
      dispatch({
        type: 'TIMEOUT_NOTIFICATION',
        payload: { message, severity, id },
      });
    }, timeout);
  };

  return (
    <appContext.Provider
      value={{
        state,
        dispatch,
        addNotification,
        closeNotification,
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export const useApp = () => useContext(appContext);
