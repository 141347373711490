import React from 'react';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import DoneIcon from '@material-ui/icons/Done';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

const DroppedItem = ({ filename, icon, onDelete }) => {
  return (
    filename && (
      <div className="p-2 w-100 d-flex justify-content-center">
        <Chip
          variant="outlined"
          color="primary"
          icon={icon}
          style={{ maxWidth: '100%' }}
          onDelete={onDelete}
          label={
            <Typography
              text_overflow="ellipsis"
              no_wrap="true"
              max_width="100%"
            >
              {filename}
            </Typography>
          }
        />
      </div>
    )
  );
};
export default DroppedItem;
