export const firebaseConfig = {
  apiKey: 'AIzaSyBkKuC6Q3gqQ9Kb0Q0i9dSXs6Ip0BJBT7g',
  authDomain: 'subsync-57348.firebaseapp.com',
  databaseURL: 'https://subsync-57348.firebaseio.com',
  projectId: 'subsync-57348',
  storageBucket: 'subsync-57348.appspot.com',
  messagingSenderId: '1026327127456',
  appId: '1:1026327127456:web:a97639a4c03749bc81b0c5',
  measurementId: 'G-5Z2V9YL2QW',
};
