import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircle from '@material-ui/icons/CheckCircle';
import GroupWork from '@material-ui/icons/GroupWork';
import Cancel from '@material-ui/icons/Cancel';

const leftTop50Style = {
  left: '50%',
  top: '50%',
};

const iconStyle = {
  ...leftTop50Style,
  position: 'absolute',
  backgroundColor: '#ffffff',
  marginTop: '-18px',
  marginLeft: '-18px',
  fontSize: '36px',
  borderRadius: '999px',
};

const Icon = ({ status }) => {
  switch (status) {
    case 'notLoaded':
      return (
        <Cancel className="color-error" fontSize="large" style={iconStyle} />
      );

    case 'loading':
      return (
        <GroupWork
          className="color-info"
          fontSize="large"
          color="primary"
          style={iconStyle}
        />
      );
    case 'loaded':
      return (
        <CheckCircle
          className="color-success"
          fontSize="large"
          style={iconStyle}
        />
      );

    default:
      return <div>error</div>;
  }
};

export const LoadingBadge = ({ status }) => {
  return (
    <div className="position-relative" style={{ left: '18px' }}>
      <Icon status={status} />
      {status === 'loading' && (
        <CircularProgress
          size={40}
          className="position-absolute color-success"
          style={{
            ...leftTop50Style,
            marginTop: '-20px',
            marginLeft: '-20px',
          }}
        />
      )}
    </div>
  );
};
