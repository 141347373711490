import React, { useState } from 'react';
import { useApp } from '../../contexts/appContext';

import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const sendBugReport = ({ db, message }) => {
  db.collection('bugReports').add({
    message: message,
  });
};

export const VotingPopover = ({ title, isOpen, close }) => {
  const {
    state: { db },
    addNotification,
  } = useApp();
  const [message, setMessage] = useState('');

  return (
    <Modal open={isOpen}>
      <div className="d-flex justify-content-center align-items-center h-100">
        <Paper
          elevation={2}
          className="p-4 d-flex flex-column align-items-center"
          style={{ width: '400px' }}
        >
          <Typography variant="h5" className="pb-3 text-secondary">
            {title}
          </Typography>
          <TextField
            variant="outlined"
            label="Message"
            className=""
            multiline
            rows={3}
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="mt-3 d-flex">
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => {
                sendBugReport({ db, message });
                close();
                addNotification({
                  message: 'Thank you !',
                  severity: 'success',
                });
              }}
              style={{ width: '120px' }}
            >
              Send
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                close();
              }}
              style={{ width: '120px' }}
            >
              Cancel
            </Button>
          </div>
        </Paper>
      </div>
    </Modal>
  );
};
