import React, { useEffect } from 'react';
import MainPage from './components/MainPage';
import { Header } from './components/Header';
import { UseChrome } from './components/UseChrome';
import LogRocket from 'logrocket';
import { firebaseConfig } from './admin';
import * as firebase from 'firebase/app';
// Add the Firebase products that you want to use
import auth from 'firebase/auth';
import firestore from 'firebase/firestore';
import { useApp } from './contexts/appContext';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-124199706-5');

const App = () => {
  const { dispatch } = useApp();

  useEffect(() => {
    LogRocket.init('t3g89s/subsync', {
      network: {
        requestSanitizer: (request) => {
          // Scrub requests body for log
          delete request.body;
          return request;
        },
      },
    });
    firebase.initializeApp(firebaseConfig);

    ReactGA.pageview('homepage');

    const db = firebase.firestore();
    dispatch({ type: 'SET_DB', payload: { db } });
  }, []); // only execute once

  return (
    <div className="App">
      <Header />
      {window.chrome ? <MainPage /> : <UseChrome />}
    </div>
  );
};

export default App;
