import React from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export const UseChrome = () => {
  return (
    <div className="container">
      <div className="jumbotron">
        <div className="d-flex justify-content-center">
          <img
            src="./chrome-logo.svg"
            alt="..."
            style={{ width: '80px', height: '80px' }}
          />
        </div>
        <h1 className="display-4">Please use Google Chrome Desktop</h1>
        <hr className="my-4" />
        <p className="lead">
          Due to the advanced functionalities required, this application only
          runs on Chrome v57+.
        </p>
        <div className="d-flex align-items-center">
          <p className="lead">
            <strong className="pr-2" style={{ fontWeight: 700 }}>
              Open this page in Google Chrome
            </strong>
            or
          </p>

          <Button
            className="ml-2"
            variant="outlined"
            color="primary"
            href="https://www.google.com/chrome/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Go to the Chrome download page
          </Button>
        </div>
      </div>
    </div>
  );
};
