import React from 'react';
import classnames from 'classnames';
import { LoadingBadge } from './LoadingBadge';

export const LoadingChip = ({ status, label }) => (
  <div className="d-inline-block border round">
    <div className="d-flex" style={{ paddingTop: '6px', paddingBottom: '6px' }}>
      <LoadingBadge status={status} />
      <div className="pl-2 pr-4" style={{ marginLeft: '36px' }}>
        {label}
      </div>
    </div>
  </div>
);
